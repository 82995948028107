import React from 'react';
import Header from "../../components/Header/Header";
import SelectInputs from "../../components/SelectInputs/SelectInputs";


function Home(){



    return(
        <>
            <Header/>
            <SelectInputs/>
        </>
    )
}


export default Home;